.custom-confirm-button{
    background-color: #0073AE !important;
    box-shadow: none !important;
}

.custom-confirm-button:hover {
    background-color: #FDB913 !important;
    color: #000;
}

.custom-cancel-button{
    box-shadow: none !important;
}

.custom-cancel-button:hover {
    background-color: #FDB913 !important;
    color: #000;
}

.custom-html-container {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}